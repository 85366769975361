<template></template>
  
<script>
import settings from "@/backend/localSettings";
import axios from 'axios';

export default {
    name: "base-page",
    components: {},
    data() {
        return {
            loadingVisible: false
        }
    },
    methods: {
        post: async function (url, args = {}) {
            this.loadingVisible = true;
            var response = await axios.post(settings.BASE_URL + url, args
                , {
                headers:
                {
                    'Content-Type':'multipart/form-data',
                    'token': settings.get_token()
                }
            });
            
            this.loadingVisible = false;
            if (settings.verify_response(response)) {
                return response.data;
            } else {
                this.$router.push("/");
                return null;
            }
        },
    },

};
</script>