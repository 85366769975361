<template>
  <div>
      <div class="overlay">
          <el-card class="login-card" v-loading="loadingVisible">
              <h1>Login</h1>
           
              <el-form ref="myForm" :model="form" :rules="rules" label-width="100%" label-position="top"  @submit.native.prevent="doLogin()">
                  <el-form-item label="Email" prop="email">
                      <el-input type="text" v-model="form.email" placeholder="Adresa ta de email" />
                  </el-form-item>

                  <el-form-item label="Parola"  prop="parola">
                      <el-input type="password" v-model="form.parola" placeholder="Parola" />
                  </el-form-item>

                  <el-form-item>
                      <el-button type="primary" native-type="submit">Login</el-button>
                  </el-form-item>
              </el-form>
              
          </el-card>
      </div>
  </div>
</template>

<script>
import settings from '@/backend/localSettings';
import axios from 'axios';

export default {
  name: "Login",
  
  data: function() {
      return {
          form:{
              email:"",
              parola:"",
              loadingVisible: false
          },
          rules: {
                email: [
                    { required: true, message: 'Emailul este obligatoriu', trigger: 'blur' }
                ],
                parola: [
                    { required: true, message: 'Parola este obligatorie', trigger: 'blur'},
                ]
            }
      }
  },
  methods:{
    post: async function (url, args = {}) {
      this.loadingVisible = true;
      var response = await axios.post(settings.BASE_URL + url, args
          , {
          headers:
          {
              'Content-Type':'multipart/form-data',
              'token': settings.get_token()
          }
      });
      
      this.loadingVisible = false;
      return response.data;
    },
    doLogin() {
        this.$refs["myForm"].validate(async (valid) => {
            if (valid) {
                this.loadingVisible = true;
                var response        = await this.post("login/do_login", this.form);
                this.loadingVisible = false;
                if( response.Eroare ){
                    this.$message.error(response.MesajEroare);
                } else {
                    //redirect
                    settings.set_token(response.Token);
                    this.$router.push("/clienti");
                }
            } else {
                console.log('error submit!!');
                return false;
            }
        });
    }
  },
  created() {
      console.log("aici");
  }
}
</script>

<style lang="less" scoped>
html, body{
  
  .overlay{
      display: flex;
      justify-content: center;
      align-items: center;
      background: fade( rgb( 49, 130, 206), 70%);
      position: absolute;
      left: 0;
      bottom:0;
      top: 0;
      right: 0;

      .login-card{
          background: white;
          width: 80%;
          @media(min-width: 768px){
            width: 445px;
          }
          border-radius: 15px;
          padding: 40px;
          
          box-shadow: rgb(0 0 0 / 5%) 0px 5px 14px;
      }
  }
}
h1{
    text-align: center;
    margin: 0;
    padding: 0;
}


</style>
