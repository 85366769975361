<template>
  <div class="container">
    <div class="sidebar">
      <button class="close-sidebar" @click="toggleMeniu">X</button>
      <a href="/#/dashboard"> <font-awesome-icon icon="house" /> Dashboard</a>
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item name="1">
          <template #title>
            <font-awesome-icon icon="globe" /> &nbsp; Clients
          </template>
          <ul>
            <li><a href="/#/clienti"> <font-awesome-icon icon="globe" /> Clients List</a></li>
            <li><a href="/#/add-client"> <font-awesome-icon icon="globe" /> Add a client</a></li>
          </ul>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template #title>
            <font-awesome-icon icon="globe" /> &nbsp; System Settings
          </template>
          <ul>
            <li><a href="/#/utilizatori"> <font-awesome-icon icon="globe" /> Users</a></li>
            <li><a href="/#/grupe-drepturi"> <font-awesome-icon icon="globe" /> Rights groups</a></li>
            <li><a href="/#/categorii-drepturi"> <font-awesome-icon icon="globe" /> Categories of rights</a></li>
            <li><a href="/#/drepturi"> <font-awesome-icon icon="globe" /> Rights</a></li>
          </ul>
        </el-collapse-item>
      </el-collapse>
      <a @click="logout()"> <font-awesome-icon icon="house" /> Logout</a>
    </div>
    <button class="menu-toggle" @click="toggleMeniu">&#9776; Toggle Meniu</button>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
import BasePage from '@/views/BasePage.vue';
import settings from '@/backend/localSettings';

export default {
  extends: BasePage,
  data() {
    return {

    }
  },
  methods: {
    toggleMeniu() {
      const sidebar = document.querySelector('.sidebar');
      sidebar.style.display = sidebar.style.display === 'none' ? 'block' : 'none';
    },
    logout() {
      settings.logout();
      this.$router.push("/");
    }

  },
  mounted() { }
};
</script>

<style lang="less" scoped>
.el-collapse {
  --el-collapse-header-text-color: #646464;
  --el-collapse-header-bg-color: transparent;
  --el-collapse-content-bg-color: transparent;
  --el-collapse-border-color: transparent;

}

.container {
  display: flex;
  text-align: left;
}

.sidebar {
  flex: 1;
  position: relative;
  background-color: #191919;
  padding: 20px;
  text-align: left;
  max-width: 280px;
  min-height: 100vh;


  .close-sidebar {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
    margin-left: 25px;
  }

  a {
    display: block;
    cursor: pointer;
    line-height: 49px;
    font-size: 14px;
    color: #646464;
    text-decoration: none;

    &:hover {
      color: white;
    }
  }
}

.content {
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  text-align: left;
}

.menu-toggle {
  display: none;
}


.close-sidebar {
  display: none;
}

@media (max-width: 768px) {

  .close-sidebar {
    display: block;
  }

  .sidebar {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 10px;
    z-index: 100;
    display: none;
  }

  .menu-toggle {
    display: block;
    padding: 10px;
    background-color: #f1f1f1;
    border: none;
    text-align: center;
    cursor: pointer;
  }

  .menu-toggle:hover {
    background-color: #ddd;
  }

  .menu-toggle:focus {
    outline: none;
  }

  .container {
    flex-direction: column;
  }

  .content {
    order: 0;
  }
}
</style>