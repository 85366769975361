import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
// import MainPage from '../views/MainPage.vue'
import LoginPage from '../views/LoginPage.vue'
import ClientiPage from '../views/clienti/ClientiList.vue'

const routes = [
  { path: '/', name: 'login', component: LoginPage },
  // { path: '/home', name: 'home',component: MainPage },
  { path: '/utilizatori', name: 'utilizatori', component: () => import('@/views/utilizatori/UtilizatoriList.vue') },
  { path: '/drepturi', name: 'drepturi', component: () => import('@/views/drepturi/DrepturiList.vue') },
  { path: '/categorii-drepturi', name: 'categorii-drepturi', component: () => import('@/views/categorii_drepturi/CategoriiDrepturiList.vue') },
  { path: '/grupe-drepturi', name: 'grupuri-drepturi', component: () => import('@/views/grupuri_drepturi/GrupuriDrepturiList.vue') },
  { path: '/contact/:id', name: 'contact', component: () => import('@/views/clienti/ContactList.vue') },
  { path: '/contact-info/:id', name: 'contact-info', component: () => import('@/views/clienti/ContactInfo.vue') },
  { path: '/clienti', name: 'clienti', component: ClientiPage },
  { path: '/contact-edit/:id/:idc', name: 'contact-edit', component: () => import('@/views/clienti/EditClientiPage.vue') },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router