<template>
  <router-view></router-view>
</template>

<style>
body{
  padding:0;
  margin:0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; 
}

.box-card{
  text-align: left;
}

.el-collapse-item__content{
  padding: 0px !important;
}

.titlu{
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding-bottom: 25px;
  font-size:40px;
}
.max-babylon-width{
  max-width: 1200px !important;
  margin: 0 auto;
}

</style>
