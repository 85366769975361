import Vue from "vue";

export default {
    version: "1.0",
    BASE_URL: 'https://babylon.brk-dev.ro/api/public/',

    set_token: function (token = '') {
        window.localStorage.setItem("babylon-token", token);
    },


    get_token: function () {
        return window.localStorage.getItem("babylon-token");
    },

    is_logged_in: function () {
        var token = this.get_token();
        return token !== "";
    },

    logout: function () {
        this.set_token('');
    },

    is_logged_in: function () {
        var token = this.get_token();

        var is_logged = token !== "";
        is_logged = token != null;

        return is_logged;
    },

    verify_response: function (response) {
        if (response.data.NotLogged) {
            return false;
        }
        return true;
    },

    verify_login_and_redirect: function (vueInstance) {
        if (!this.is_logged_in()) {
            vueInstance.$router.push("/");
        }
    },
};