import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/style.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { createStore } from "vuex";


import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import vueCountryRegionSelect from 'vue-country-region-select'
library.add(fas, far);
import VueTheMask from 'vue-the-mask'




const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router);
app.use(ElementPlus);
app.use(VueTheMask);


app.use(vueCountryRegionSelect);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.mount('#app')
