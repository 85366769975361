<template>
    <main-page>
        <div class="clientiPage">
            <div class="titlu max-babylon-width">
                Clienti
                <el-tooltip content="Adauga client">
                    <el-button type="success" size="small" icon="CirclePlus" @click="adauga_item()"></el-button>
                </el-tooltip>
            </div>
            <el-card class="box-card max-babylon-width">
                <template #header>
                    <div class="card-header">
                        <span>Filtre</span>
                    </div>
                </template>
                <div>
                    <el-form :inline="true" :model="Filters" @submit.native.prevent>
                        <el-form-item label="Nume">
                            <el-input v-model="Filters.Nume" @input="refresh_info()" placeholder="Nume" />
                        </el-form-item>

                        <el-form-item label="Cui">
                            <el-input v-model="Filters.Cui" @input="refresh_info()" placeholder="CUI" />
                        </el-form-item>

                        <el-form-item label="Status">
                            <el-select @change="refresh_info()" v-model="Filters.Status">
                                <el-option label="Deleted" value="deleted" />
                                <el-option label="Inactive" value="inactive" />
                                <el-option label="Active" value="active" />
                            </el-select>
                        </el-form-item>


                    </el-form>
                </div>
            </el-card>
            <el-card class="max-babylon-width" style="margin-top: 25px;">
                <el-pagination style="margin-bottom:25px" background v-model:current-page="PaginationInfo.Page"
                    v-model:page-size="PaginationInfo.PerPage" :page-sizes="PaginationInfo.PageSizes" :small="true"
                    layout="sizes, prev, pager, next" :total="PaginationInfo.RowCount" @size-change="refresh_info"
                    @current-change="refresh_info" />
                <el-table :data="Results" border v-loading="loadingVisible">
                    <el-table-column width="40">
                        <template v-slot="scope">
                            <el-checkbox @change="checkMethod(scope)" />
                        </template>
                    </el-table-column>

                    <el-table-column min-width="200" prop="comp_name" label="Nume" />

                    <el-table-column prop="comp_status" label="Status" width="100">
                        <template #default="slot">
                            <el-tag class="ml-2" :type="slot.row.comp_status == 'active' ? 'success' : 'danger'">{{
                                slot.row.comp_status }}</el-tag>
                        </template>
                    </el-table-column>

                    <el-table-column label="Actions" width="160" fixed="right">
                        <template #default="slot">
                            <el-tooltip content="Invoices">
                                <el-button size="small" icon="Money" circle type="success"></el-button>
                            </el-tooltip>
                            <el-tooltip content="Edit">
                                <el-button size="small" @click="editeaza_contacte(slot)" icon="Edit" type="warning"
                                    circle></el-button>
                            </el-tooltip>
                            <el-tooltip content="Delete">
                                <el-button size="small" type="danger" @click="sterge_item(slot)" icon="Delete"
                                    circle></el-button>
                            </el-tooltip>
                            <el-tooltip content="Persoane de contact">
                                <el-button size="small" type='primary' @click="arata_contacte(slot)" icon="UserFilled"
                                    circle></el-button>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination style="margin-top:25px;" background v-model:current-page="PaginationInfo.Page"
                    v-model:page-size="PaginationInfo.PerPage" :page-sizes="PaginationInfo.PageSizes" :small="true"
                    layout="sizes, prev, pager, next" :total="PaginationInfo.RowCount" @size-change="refresh_info"
                    @current-change="refresh_info" />
            </el-card>

        </div>

    </main-page>
</template>
<script>
import MainPage from "@/views/MainPage.vue";
import settings from '@/backend/localSettings';



export default {
    extends: MainPage,
    components: {
        "main-page": MainPage,
    },
    data() {
        return {
            loadingVisible: false,
            Results: [],
            Info: {},
            Filters: { Nume: "", Cui: "", Status: "active" },
            OrderBy: {},
            PaginationInfo: { Page: 1, PerPage: 10, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        };
    },
    methods: {


        async get_info() {
            this.refresh_info();
        },

        async refresh_info_timeout() {
            var response = await this.post("clienti/refresh_info", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo });
            if (response != null) {
                this.Results = response.Results;
                this.PaginationInfo = response.PaginationInfo;
            }
        },

        async refresh_info() {
            setTimeout(this.refresh_info_timeout, 200);
        },

        async adauga_item() {

        },

        async arata_contacte(item) {
            this.$router.push(`/contact/${item.row.comp_id}`);
        },

        async editeaza_contacte(item) {

            this.$router.push(`/contact-edit/${item.row.comp_id}`);
        },

        async sterge_item(item) {
        },

        adaugare() {
        },

    },
    mounted() {
        this.get_info();
    },
};
</script>
<style scoped>
.clientiPage {
    width: 70vw;
}
</style>